import { Subscriber } from './meetings-model.model';
import { AttachmentInfo } from './user-attachment.model';

export class AnnouncementSearch {
    constructor(
        public schoolId?: string,
        public status?: AnnouncementStatus
    ) {}
}

export class UserAnnouncementSearch {
    constructor(
        public schoolId?: string,
        public subject?: string,
        public condition?: AnnouncementCondition,
        public afterLogin?: boolean
    ) {}
}

export enum AnnouncementCondition {
    VISIBLE = 'VISIBLE',
    HIDDEN = 'HIDDEN',
}

export enum AnnouncementStatus {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
}

export class Announcement {
    constructor(
        public id?: string,
        public schoolId?: string,
        public subject?: string,
        public content?: string,
        public startDate?: Date,
        public endDate?: Date,
        public startTime?: Date,
        public endTime?: Date,
        public isEndless?: boolean,
        public members?: Subscriber[],
        public status?: AnnouncementStatus,
        public condition?: AnnouncementCondition,
        public attachments?: AttachmentInfo[],
        public showOnLogin?: boolean
    ) {
        this.id = id ? id : null;
        this.schoolId = schoolId ?? null;
        this.subject = subject ?? '';
        this.content = content ?? '';
        this.startDate = startDate ?? null;
        this.endDate = endDate ?? null;
        this.startTime = startTime ?? null;
        this.endTime = endTime ?? null;
        this.isEndless = isEndless ?? false;
        this.members = members ?? [];
        this.status = status ?? AnnouncementStatus.DRAFT;
        this.condition = condition ?? AnnouncementCondition.VISIBLE;
        this.attachments = attachments ?? [];
        this.showOnLogin = showOnLogin ?? false;
    }
}
